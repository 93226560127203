import { Fragment, lazy } from 'react'
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
import PublicRoute from '@components/routes/PublicRoute'
import { isObjEmpty } from '@utils'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

const TemplateTitle = '%s - Nustore'
const DefaultRoute = '/home'

const OrgBlockedPage = lazy(() => import('../../views/OrgBlockedPage'))
const StoreFrontHomePage = lazy(() => import('../../views/storefront/home/index'))
const CategoryProducts = lazy(() =>
  import('../../views/storefront/productListing/CategoryProducts')
)
const ProductDetails = lazy(() => import('../../views/storefront/products/ProductDetails'))
const AboutUsPage = lazy(() => import('../../views/storefront/about/index'))
const ContactUsPage = lazy(() => import('../../views/storefront/contactUs/index'))
const ShopPage = lazy(() => import('../../views/storefront/shop/index'))
const LoginPage = lazy(() => import('../../views/authentication/Login'))
const Account = lazy(() => import('../../views/account'))
const Checkout = lazy(() => import('../../views/storefront/checkout'))
const PaymentConfirmation = lazy(() =>
  import('../../views/storefront/checkout/PaymentConfirmation')
)
const PoliciesPage = lazy(() => import('../../views/storefront/policies'))
const SignUp = lazy(() => import('../../views/authentication/SignUp'))
const SignupTermsConditionsPage = lazy(() => import('../../views/storefront/TermsConditions'))

const Routes = [
  {
    path: '/error',
    exact: true,
    element: <OrgBlockedPage />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/login',
    exact: true,
    element: <LoginPage />,
    meta: {
      layout: 'blank'
    }
    // layout: 'BlankLayout',
    // meta: {
    //   authRoute: true
    // }
  },
  {
    path: '/signup',
    exact: true,
    element: <SignUp />,
    meta: {
      layout: 'blank'
    }
    // layout: 'BlankLayout',
    // meta: {
    //   authRoute: true
    // }
  },
  {
    // path: '/:orgSlug/aboutUs',
    path: '/aboutUs',
    element: <AboutUsPage />
  },
  {
    // path: '/:orgSlug/policies/:policyType',
    path: '/policies/:policyType',
    element: <PoliciesPage />
  },
  {
    // path: '/:orgSlug/contactUs',
    path: '/contactUs',
    element: <ContactUsPage />
  },
  {
    // path: '/:orgSlug/checkout',
    path: '/checkout',
    exact: true,
    meta: {
      className: 'ecommerce-application'
    },
    element: <Checkout />
  },
  {
    // path: '/:orgSlug/checkout/paymentSuccess',
    path: '/checkout/paymentStatus',
    element: <PaymentConfirmation />,
    meta: {
      layout: 'blank'
    }
  },
  {
    // path: '/:orgSlug/termsConditions',
    path: '/termsConditions',
    element: <SignupTermsConditionsPage />,
    meta: {
      layout: 'blank'
    }
  },
  {
    // path: '/:orgSlug/shop/:catSlug/:productSlug',
    path: '/shop/:catSlug/:productSlug',
    element: <ProductDetails />
  },
  {
    // path: '/:orgSlug/shop/:catSlug',
    path: '/shop/:catSlug',
    element: <CategoryProducts />
  },
  {
    // path: '/:orgSlug/shop',
    path: '/shop',
    element: <ShopPage />,
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    // path: '/:orgSlug/account/:option',
    path: '/account/:option',
    exact: true,
    meta: {
      appLayout: true,
      className: 'email-application'
    },
    element: <Account />
  },
  {
    // path: '/:orgSlug',
    path: '/',
    exact: true,
    index: true,
    element: <StoreFrontHomePage />
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const getRoutesForLayout = (layout, defaultLayout) => {
  const layoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        layoutRoutes.push(route)
      }
      return layoutRoutes
    })
  }
  return layoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']
  const allRoutes = []

  layouts.forEach((layoutItem) => {
    const layoutRoutes = getRoutesForLayout(layoutItem, defaultLayout)
    allRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: layoutRoutes
    })
  })
  return allRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
