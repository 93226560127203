
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { axiosInstance } from '../utility/Utils'

const setLoading = createAction('setOrgLoading')

export const getOrgIdFromSlug = createAsyncThunk('getOrgId', async (slug, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('storefront/getOrgId', {
    params: {
      slug
    }
  })
  return response.data
})

export const getOrgForCustomDomain = createAsyncThunk('getOrgForDomain', async (domain, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('storefront/getOrgForDomain', {
    params: {
      domain
    }
  })
  return response.data
})

export const getTestimonials = createAsyncThunk('getTestimonials', async (data, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('storefront/store/testimonials')
  return response.data
})

export const getStoreBanners = createAsyncThunk('getStoreBanners', async (data, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('storefront/store/banners')
  return response.data
})

export const subscribeNewsLetter = createAsyncThunk('subscribeNewsLetter', async (payload) => {
  const response = await axiosInstance.post('storefront/store/newsLetterSubscribe', payload)
  return response.data
})

export const getOrgPaymentOptions = createAsyncThunk('getOrgpaymentOptions', async () => {
  const response = await axiosInstance.get('storefront/payment_options')
  return response.data
})

export const orgSlice = createSlice({
  name: 'org',
  initialState: {
    isLoading: false,
    org: {},
    params: {},
    banners: [],
    testimonials: [],
    paymentOptions: []
  },
  reducers: {
    // resetProductDetail(state) {
    //   state.productDetail = {}
    // }
    resetOrgState(state) {
      state.org = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoading, (state) => {
        state.isLoading = true
      })
      .addCase(getOrgIdFromSlug.fulfilled, (state, action) => {
        state.org = action.payload?.data
        axiosInstance.defaults.headers.common['org-id'] = state.org?.id
        state.isLoading = false
      })
      .addCase(getOrgForCustomDomain.fulfilled, (state, action) => {
        state.org = action.payload?.data
        axiosInstance.defaults.headers.common['org-id'] = state.org?.id
        state.isLoading = false
      })
      .addCase(getStoreBanners.fulfilled, (state, action) => {
        state.banners = action.payload?.data
        state.isLoading = false
      })
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.testimonials = action.payload?.data
        state.isLoading = false
      })
      .addCase(subscribeNewsLetter.fulfilled, (state, action) => {
        state.newsLetterSubscribed = action.payload?.data
        state.isLoading = false
      })
      .addCase(getOrgPaymentOptions.fulfilled, (state, action) => {
        state.paymentOptions = action.payload?.data || []
      })
  }
})

export default orgSlice.reducer
