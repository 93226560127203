import { Link } from 'react-router-dom'
import { User } from 'react-feather'

const UserDropdown = ({ userData, org }) => {
  return (
    <li className="dropdown-user nav-item" style={{ marginLeft: 20 }}>
      <Link
        href="/"
        to={`/account/account`}
        className="nav-link dropdown-user-link dropdown-user nav-item"
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="fw-bold" style={{ fontSize: 15 }}>
            {userData?.name}
          </span>
        </div>
        <User className="ficon" size="10rem" />
      </Link>
    </li>
  )
}

export default UserDropdown
