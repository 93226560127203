// ** Dropdowns Imports
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, NavItem, NavLink } from 'reactstrap'
import { useAuth } from '../../../../providers/AuthProvider'
import UserDropdown from './UserDropdown'
import CartDropdown from './CartDropdown'

const NavbarUser = (props) => {
  const { userLoggedIn } = useAuth()
  const { org } = props

  return (
    <React.Fragment>
      {/* <NavItem className="d-m">
          <NavLink tag={Link} to={`/${org.slug}`}>            
            <h3 style={{ color: 'black' }}>{org?.name}</h3>
          </NavLink>
        </NavItem> */}

      <NavLink
        tag={Link}
        to={`/`}
        className="d-flex flex-row justify-content-center align-items-center"
      >
        {org.logo && (
          <img
            src={org.logo.url}
            alt="logo"
            style={{ width: 40, height: 40, objectFit: 'contain' }}
          />
        )}
        <h3 style={{ color: 'black', marginTop: 10, fontSize: '2.5vh', marginLeft: 10 }}>
          {org?.name}
        </h3>
      </NavLink>
      <div className="d-flex ms-auto ">
        <ul className="nav navbar-nav d-none d-lg-flex align-items-center me-1 ">
          <NavItem className="nav-item">
            <NavLink
              className="nav-link-style"
              tag={Link}
              to={`/shop`}
              style={{ color: 'black', fontSize: '1.7vh', fontWeight: '500' }}
            >
              <span className="ml-1">SHOP</span>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item ">
            <NavLink
              className="nav-link-style"
              tag={Link}
              to={'/aboutUs'}
              style={{ color: 'black', fontSize: '1.7vh', fontWeight: '500' }}
            >
              <span className="mx-1">ABOUT US</span>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item ">
            <NavLink
              className="nav-link-style"
              tag={Link}
              to={`/contactUs`}
              style={{ color: 'black', fontSize: '1.7vh', fontWeight: '500' }}
            >
              <span className="ml-1">CONTACT</span>
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav align-items-center mr-1">
          {userLoggedIn ? (
            <React.Fragment>
              <CartDropdown {...props} />
              <UserDropdown {...props} />
            </React.Fragment>
          ) : (
            <Button tag={Link} to={`/login`} block color="primary" className="">
              SIGN IN
            </Button>
          )}
        </ul>
      </div>
    </React.Fragment>
  )
}
export default NavbarUser
