/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { PAGINATION_COUNT } from '../../constants/constants'
import { axiosInstance } from '../../utility/Utils'
const setLoading = createAction('setCatalogLoading')

// ***** Product Endpoints ********

export const getProducts = createAsyncThunk(
  'getProducts',
  async (pageIndex, { dispatch, getState }) => {
    dispatch(setLoading())
    //fetching prev page
    let marker
    if (pageIndex < getState().catalog.paginationIndex) {
      marker = getState().catalog.markerList.at(pageIndex)
    }
    //getching next page
    if (pageIndex > getState().catalog.paginationIndex) {
      marker = getState().catalog.products.at(-1)?.id
    }
    const params = { limit: PAGINATION_COUNT, marker }
    const response = await axiosInstance.get('storefront/products', { params })
    if (pageIndex !== undefined) {
      dispatch(
        addToMarkerList({
          pageIndex,
          paginationIndex: getState().catalog.paginationIndex,
          products: getState().catalog.products
        })
      )
      dispatch(setPaginationIndex(pageIndex))
    }
    return response.data
  }
)

export const getProduct = createAsyncThunk('getProduct', async (id, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get(`storefront/products/${id}`)
  return response.data
})

export const getProductBySlug = createAsyncThunk('getProductBySlug', async (slug, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get(`storefront/products/getProductBySlug`, {
    params: { slug }
  })
  return response.data
})

export const getProductIdFromSlug = createAsyncThunk('getProductId', async (slug, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('storefront/getProductId', {
    params: {
      slug
    }
  })
  return response.data
})

export const getFeaturedProducts = createAsyncThunk(
  'getFeaturedProducts',
  async (params, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.get('storefront/products?featured=true')
    return { params, data: response.data }
  }
)

// ***** Categories Endpoints ********

export const getCategories = createAsyncThunk('getCategories', async (data, { dispatch }) => {
  // dispatch(setLoading())
  const response = await axiosInstance.get('storefront/categories')
  return response.data
})

export const getCategoryFromSlug = createAsyncThunk(
  'getCategoryFromSlug',
  async (slug, { dispatch }) => {
    // dispatch(setLoading())
    const response = await axiosInstance.get('storefront/categories/getBySlug', {
      params: { slug }
    })
    return response.data
  }
)

export const getProductsByCategorySlug = createAsyncThunk(
  'getProductsByCategorySlug',
  async (slug, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.get(`storefront/categories/getProductsByCategorySlug/`, {
      params: { slug }
    })
    return response.data
  }
)

export const getProductsByCategoryId = createAsyncThunk(
  'getProductsByCategoryId',
  async (categoryId, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.get(`storefront/categories/${categoryId}/products`)
    return response.data
  }
)

// ***** Enquiry Endpoints ********

export const addEnquiry = createAsyncThunk('addEnquiry', async (body) => {
  const response = await axiosInstance.post('storefront/enquiry', body)
  return response.data
})

// ***** Redux ********

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    isLoading: false,
    categories: [],
    products: [],
    productDetail: {},
    category: null,
    markerList: [null], //Stores (n-1) pages' last item id
    paginationIndex: 0
  },
  reducers: {
    resetProductDetail(state) {
      state.productDetail = {}
    },
    setPaginationIndex(state, action) {
      state.paginationIndex = action.payload || 0
    },
    addToMarkerList(state, action) {
      const { pageIndex, paginationIndex, products } = action.payload
      console.log({ pageIndex, paginationIndex })
      if (pageIndex < paginationIndex) {
        state.markerList = state.markerList.slice(0, pageIndex + 1)
      } else {
        console.log(products.at(-1))
        state.markerList.push(products.at(-1).id)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoading, (state) => {
        state.isLoading = true
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data || []
        state.isLoading = false
      })
      .addCase(getFeaturedProducts.fulfilled, (state, action) => {
        state.featuredProducts = action.payload.data.data || []
        state.isLoading = false
      })
      .addCase(getProductsByCategorySlug.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.products = action.payload.data || []
        state.totalProducts = action.payload.data?.length
        state.isLoading = false
      })
      .addCase(getProductsByCategoryId.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.products = action.payload.data || []
        state.totalProducts = action.payload.data?.length
        state.isLoading = false
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.data
        state.isLoading = false
      })
      .addCase(getProductBySlug.fulfilled, (state, action) => {
        state.productDetail = action.payload.data
        state.isLoading = false
      })
      .addCase(getProductIdFromSlug.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getCategoryFromSlug.fulfilled, (state, action) => {
        // state.isLoading = false
        state.category = action.payload.data
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data || []
        // state.isLoading = false
      })
  }
})

export const { resetProductDetail, addToMarkerList, setPaginationIndex } = catalogSlice.actions

export default catalogSlice.reducer
