// ** Redux Imports
import { async } from '@firebase/util'
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosInstance } from '../../utility/Utils'

const setLoading = createAction('setCheckoutLoading')

const setFetchingOrders = createAction('setFetchingOrders')

export const clearUserState = createAction('clearState')

export const setAddress = createAction('setAddress')

export const getCart = createAsyncThunk('appEcommerce/getCart', async (_, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.get('/storefront/carts')
  return response.data
})

const createCart = createAsyncThunk('appEcommerce/createCart', async (_, { dispatch }) => {
  dispatch(setLoading())
  const response = await axiosInstance.post(`/storefront/carts`)
  await dispatch(getCart())
  return response.data
})

export const addToCart = createAsyncThunk(
  'appEcommerce/addToCart',
  async (body, { dispatch, getState }) => {
    dispatch(setLoading())
    if (getState().checkout.cart.id === undefined) {
      await dispatch(createCart())
    }
    const response = await axiosInstance.post(
      `/storefront/carts/${getState().checkout.cart.id}/lineItems`,
      body
    )
    // await dispatch(getCart())
    return response.data
  }
)

export const updateCartItem = createAsyncThunk(
  'appEcommerce/updateCartItem',
  async ({ cartId, lineItemId, body }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.put(
      `/storefront/carts/${cartId}/lineItems/${lineItemId}`,
      body
    )
    // await dispatch(getCart())
    return response.data
  }
)

export const deleteCartItem = createAsyncThunk(
  'appEcommerce/deleteCartItem',
  async ({ cartId, lineItemId }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.delete(
      `/storefront/carts/${cartId}/lineItems/${lineItemId}`
    )
    // await dispatch(getCart())
    return response.data
  }
)

export const applyCoupon = createAsyncThunk(
  'appEcommerce/applyCoupon',
  async ({ cartId, couponId }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.put(`/storefront/carts/${cartId}/coupons/${couponId}`)
    // await dispatch(getCart())
    return response.data
  }
)

export const removeCoupon = createAsyncThunk(
  'appEcommerce/removeCoupon',
  async ({ cartId, couponId }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.delete(`/storefront/carts/${cartId}/coupons/${couponId}`)
    // await dispatch(getCart())
    return response.data
  }
)

export const getStoreCoupons = createAsyncThunk('appEcommerce/getStoreCoupons', async () => {
  const response = await axiosInstance.get('/storefront/coupons')
  return response.data
})

export const addShippingMethod = createAsyncThunk(
  'appEcommerce/addShippingMethod',
  async ({ cartId, body }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.put(`/storefront/carts/${cartId}/shippingMethod`, body)
    return response.data
  }
)

export const addShippingAddress = createAsyncThunk(
  'appEcommerce/addShippingAddress',
  async ({ cartId, body }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.put(`/storefront/carts/${cartId}/shippingAddress`, body)
    return response.data
  }
)

export const removeShippingAddress = createAsyncThunk(
  'appEcommerce/removeShippingAddress',
  async (cartId, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.delete(`/storefront/carts/${cartId}/shippingAddress`)
    return response.data
  }
)

export const getAllOrders = createAsyncThunk(
  'appEcommerce/getAllOrders',
  async (_, { dispatch }) => {
    dispatch(setFetchingOrders())
    const response = await axiosInstance.get('/storefront/orders')
    return response.data
  }
)

export const getOrderById = createAsyncThunk('appEcommerce/getOrderById', async (id) => {
  const response = await axiosInstance.get(`/storefront/orders/${id}`)
  return response.data
})

export const createOrder = createAsyncThunk(
  'appEcommerce/createOrder',
  async (body, { dispatch }) => {
    const response = await axiosInstance.post('/storefront/orders', body)
    dispatch(getCart())
    return response.data
  }
)

export const cancelOrder = createAsyncThunk(
  'appEcommerce/cancelOrder',
  async (orderId, { dispatch }) => {
    await axiosInstance.put(`/storefront/orders/${orderId}/cancelOrder`)
    dispatch(getAllOrders())
  }
)

export const verifyPayment = createAsyncThunk(
  'appEcommerce/verifyPayment',
  async ({ id, body }, { dispatch }) => {
    dispatch(setLoading())
    const response = await axiosInstance.post(`/storefront/orders/${id}/payments/verify`, body)
    dispatch(getCart())
    return response.data
  }
)

export const getShippingMethods = createAsyncThunk('appEcommerce/getPaymentmethods', async () => {
  const response = await axiosInstance.get('/storefront/shipping_methods')
  return response.data
})

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    isLoading: false,
    isFetchingOrders: false,
    cart: {},
    coupons: [],
    orders: [],
    orderDetail: {},
    billingAddress: {},
    shippingMethods: []
  },
  reducers: {
    addBillingAddress(state, action) {
      state.billingAddress = action.payload || {}
    },
    setOrderDetail(state, action) {
      state.orderDetail = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearUserState, (state) => {
        state.cart = {}
      })
      .addCase(setLoading, (state) => {
        state.isLoading = true
      })
      .addCase(setFetchingOrders, (state) => {
        state.isFetchingOrders = true
      })
      .addCase(setAddress, (state, action) => {
        state.billingAddress = action.payload
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload.data || {}
        state.isLoading = false
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        if (action.payload.data.cart_deleted) {
          state.cart = {}
        } else {
          state.cart = action.payload.data || state.cart
        }
        state.isLoading = false
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(removeCoupon.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(getStoreCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload.data || []
      })
      .addCase(addShippingMethod.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(addShippingAddress.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(removeShippingAddress.fulfilled, (state, action) => {
        state.cart = action.payload.data || state.cart
        state.isLoading = false
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.orders = action.payload.data || []
        state.isFetchingOrders = false
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.orderDetail = action.payload.data
      })
      .addCase(verifyPayment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getShippingMethods.fulfilled, (state, action) => {
        state.shippingMethods = action.payload.data
      })
  }
})

export const { addBillingAddress, setOrderDetail } = checkoutSlice.actions

export default checkoutSlice.reducer
