// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosInstance } from '../utility/Utils'

import { auth } from '../firebase/firebase'
import { signOut } from 'firebase/auth'

export const setAuthLoading = createAction('setAuthLoading')

export const getCustomerAddress = createAsyncThunk('customer/getAddress', async () => {
  const response = await axiosInstance.get('/storefront/users/address')
  return response.data
})

export const getCustomer = createAsyncThunk(
  'authentication/getCustomer',
  async (_, { dispatch }) => {
    dispatch(setAuthLoading(true))
    const response = await axiosInstance.get('/storefront/users/')
    dispatch(getCustomerAddress())
    return response.data
  }
)

export const handleSignup = createAsyncThunk(
  'authentication/signup',
  async (body, { dispatch }) => {
    dispatch(setAuthLoading(true))
    const response = await axiosInstance.post('/storefront/users/signup', body)
    return response.data
  }
)

export const addCustomerAddress = createAsyncThunk('customer/addAddress', async (body) => {
  const response = await axiosInstance.post('/storefront/users/address', body)
  return response.data
})

export const deleteCustomerAddress = createAsyncThunk(
  'customer/deleteAddress',
  async (addressId) => {
    const response = await axiosInstance.delete(`/storefront/users/address/${addressId}`)
    return response.data
  }
)

export const handleSignOut = createAsyncThunk('authentication/signout', async () => {
  await signOut(auth)
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    isLoading: true,
    userData: {},
    address: []
  },  
  extraReducers: (builder) => {
    builder
      .addCase(setAuthLoading, (state, action) => {
        state.isLoading = action.payload
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.userData = action.payload.data?.customer
        state.isLoading = false
      })
      .addCase(getCustomerAddress.fulfilled, (state, action) => {
        state.address = action.payload.data || []
      })
      .addCase(addCustomerAddress.fulfilled, (state, action) => {
        state.address = action.payload.data.address || []
      })
      .addCase(deleteCustomerAddress.fulfilled, (state, action) => {
        state.address = action.payload.data || []
      })
      .addCase(handleSignup.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(handleSignOut.fulfilled, (state, action) => {
        state.userData = {}
      })
  }
})

export default authSlice.reducer
