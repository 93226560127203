import { Link, useLocation } from 'react-router-dom'
import { useEffect, Fragment, useState } from 'react'
import InputNumber from 'rc-input-number'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ShoppingCart, X, Plus, Minus } from 'react-feather'
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Badge, Button } from 'reactstrap'
import '@styles/react/libs/input-number/input-number.scss'

import defaultImage from '../../../../assets/images/default_image.png'
import { getCart, updateCartItem, deleteCartItem } from '../../../../redux/storefront/checkout'

const CartDropdown = ({ dispatch, checkout, org }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    dispatch(getCart())
  }, [])

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  if (!checkout.cart) {
    return null
  }

  if (!org?.checkout_enabled) {
    return null
  }

  const renderCartItems = () => {
    if (checkout.cart.line_items?.length) {
      return (
        <Fragment>
          <PerfectScrollbar
            className="scrollable-container media-list"
            options={{
              wheelPropagation: false
            }}
          >
            {checkout.cart?.line_items.map((item) => {
              const inStock =
                !item.manage_inventory ||
                (item.manage_inventory && item.inventory - item.quantity >= 0)
              let basePath = location.pathname
              if (basePath.indexOf('checkout') !== 0) {
                basePath = basePath.replace('/checkout', '/shop')
              }
              const url = `${basePath}${basePath.endsWith('/') ? '' : '/'}${item.category_slug}/${
                item.product_slug
              }`
              return (
                <div key={item.id} className="list-item align-items-center">
                  <img
                    className="d-block rounded me-1"
                    src={item?.image?.url || defaultImage}
                    // alt={item.product_name}
                    width="62"
                  />
                  <div className="list-item-body flex-grow-1">
                    <X
                      size={14}
                      className="cart-item-remove"
                      onClick={() =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        dispatch(deleteCartItem({ cartId: checkout.cart.id, lineItemId: item.id }))
                      }
                    />
                    <div className="media-heading">
                      <h6 className="cart-item-title">
                        <Link
                          className="text-body"
                          to={url}
                          onClick={() => {
                            // handleDropdownItemClick(item.id)
                            toggle()
                          }}
                        >
                          {item.product_name}
                        </Link>
                      </h6>
                      {/* <small className="cart-item-by">by {item.brand}</small> */}
                    </div>
                    <div className="cart-item-qty">
                      <InputNumber
                        min={1}
                        max={item.manage_inventory  ? item.inventory : 20}
                        upHandler={<Plus />}
                        className="cart-input"
                        defaultValue={item.quantity}
                        downHandler={<Minus />}
                        // disabled={!inStock}
                        onChange={(val) => {
                          dispatch(
                            updateCartItem({
                              cartId: checkout.cart.id,
                              lineItemId: item.id,
                              body: {
                                product_id: item.product_id,
                                variant_id: item.variant_id,
                                quantity: val
                              }
                            })
                          )
                        }}
                      />
                    </div>
                    <h5 className="cart-item-price">{`${checkout.cart.currency_code} ${
                      item.sale_price || item.list_price
                    }`}</h5>
                  </div>
                </div>
              )
            })}
          </PerfectScrollbar>
          <li className="dropdown-menu-footer">
            <div className="d-flex justify-content-between mb-1">
              <h6 className="fw-bolder mb-0">Total:</h6>
              <h6 className="text-primary fw-bolder mb-0">
                {`${checkout.cart.currency_code} ${Number(checkout.cart.total_sale_amt).toFixed(
                  2
                )}`}
              </h6>
            </div>
            <Button tag={Link} to={`/checkout`} color="primary" block onClick={toggle}>
              Checkout
            </Button>
          </li>
        </Fragment>
      )
    } else {
      return <p className="m-0 p-1 text-center">Your cart is empty</p>
    }
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      tag="li"
      className="dropdown-cart nav-item me-25"
    >
      <DropdownToggle tag="a" className="nav-link position-relative">
        <ShoppingCart className="ficon" />
        {checkout.cart.line_items?.length > 0 ? (
          <Badge pill color="primary" className="badge-up">
            {checkout.cart.line_items.length}
          </Badge>
        ) : null}
      </DropdownToggle>
      <DropdownMenu end tag="ul" className="dropdown-menu-media dropdown-cart mt-0">
        <li className="dropdown-menu-header">
          <DropdownItem tag="div" className="d-flex" header>
            <h4 className="notification-title mb-0 me-auto">My Cart</h4>
            <Badge color="light-primary" pill>
              {checkout.cart.line_items?.length || 0} Items
            </Badge>
          </DropdownItem>
        </li>
        {renderCartItems()}
      </DropdownMenu>
    </Dropdown>
  )
}

export default CartDropdown
