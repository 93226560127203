// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosInstance } from '../utility/Utils'

export const getPaymentConfig = createAsyncThunk('config/paymentConfig', async () => {
  const response = await axiosInstance.get('config/paymentOptions')
  return response.data
})

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    paymentConfig: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentConfig.fulfilled, (state, action) => {
      state.paymentConfig = action.payload.data || []
    })
  }
})

export default configSlice.reducer
