import { useContext, useState, useEffect, createContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomer, handleSignOut } from '../redux/authentication'
import { unwrapResult } from '@reduxjs/toolkit'
import { auth } from '../firebase/firebase'
import { axiosInstance } from '../utility/Utils'

const TAG = 'Auth: AuthProvider -> '

export const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const store = useSelector((store) => store)
  const org = store.org

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [customerProfileCreationPending, setCustomerProfileCreationPending] = useState(false)
  const [isSigninRegistering, setIsSigninRegistering] = useState(false)

  const authStatusChangedCallback = async (user) => {
    console.log(`${TAG} authStatusChangedCallback`)
    if (!isSigninRegistering) {
      setLoading(true)
    }

    if (!user) {
      console.log(`${TAG} onAuthStateUpdate: User not found`)
      setLoading(false)
      setUserLoggedIn(false)
      return
    }
    console.log(`${TAG} onAuthStateUpdate: User found`)

    const token = await auth.currentUser.getIdToken()
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    // const org = store.org
    // if (org && Object.keys(org).length !== 0) {
    if (!isSigninRegistering) {
      try {
        const getCustomerAction = await dispatch(getCustomer())
        const result = unwrapResult(getCustomerAction)
        console.log(result)
        if (!result?.data?.customer_created) {
          setLoading(false)
          setUserLoggedIn(false)
          dispatch(handleSignOut())
          setCustomerProfileCreationPending(true)
          return
        }
        setCustomerProfileCreationPending(false)
        setLoading(false)
        setUserLoggedIn(true)
      } catch (e) {
        setError(e)
        console.error(e)
      }
    }
  }

  const onAuthError = (err) => {
    console.log(`onAuth Error:${JSON.stringify(err)}`)
  }

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, authStatusChangedCallback, onAuthError)
    return subscriber // unsubscribe on unmount
  }, [isSigninRegistering])

  if (loading) {
    return null
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        userLoggedIn,
        error,
        customerProfileCreationPending,
        setIsSigninRegistering
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
