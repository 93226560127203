// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'

// new reducers
import catalog from './storefront/catalog'
import checkout from './storefront/checkout'
import org from './org'
import auth from './authentication'
import config from './config'

const rootReducer = {
  // config,
  navbar,
  layout,
  auth,
  org,
  catalog,
  checkout,
  config
}

export default rootReducer
