// ** Icons Import
import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, CardText } from 'reactstrap'
import { useSelector } from 'react-redux'

const Footer = () => {
  const orgState = useSelector((s) => s.org)
  const orgData = orgState.org

  if (!orgData || Object.keys(orgData).length === 0) {
    return null
  }

  const address = orgData?.address
  let addressText = ''
  if (address) {
    if (address.line1) addressText = addressText + address.line1
    if (address.line2) addressText = `${addressText}, ${address.line2}`
    if (address.city) addressText = `${addressText}, ${address.city}`
    if (address.postal_code) addressText = `${addressText}, ${address.postal_code}`
    if (address.state) addressText = `${addressText}, ${address.state}`
    if (address.country) addressText = `${addressText}, ${address.country}`
  }

  const renderShopAddress = () => {
    return (
      <Col className="mb-4 mb-md-0" md="4" xs="12">
        <h4 className="mt-2 mb-1">{orgData?.name}</h4>
        <div className="w-75 mx-auto">
          <CardText>{addressText}</CardText>
        </div>
        {orgData?.email && <CardText>Email: {orgData?.email}</CardText>}
        {orgData?.mobile && <CardText>Contact: {orgData?.mobile}</CardText>}
      </Col>
    )
  }

  const renderPolicies = () => {
    return (
      <Col className="mb-4 mb-md-0" md="4" xs="12">
        <div className="w-75 mx-auto">
          <h4 className="mt-2 mb-1">Policies</h4>
          {orgData?.policies?.terms_conditions?.content && (
            <CardText style={{ textDecoration: 'underline' }}>
              <Link to={`policies/terms_conditions`} target="_blank">
                {' '}
                {`Terms & Conditions`}{' '}
              </Link>
            </CardText>
          )}
          {orgData?.policies?.payment_policy?.content && (
            <CardText style={{ textDecoration: 'underline' }}>
              <Link to={`policies/payment_policy`} target="_blank">
                {' '}
                Payment Policy{' '}
              </Link>
            </CardText>
          )}
          {orgData?.policies?.privacy_policy?.content && (
            <CardText style={{ textDecoration: 'underline' }}>
              <Link to={`policies/privacy_policy`} target="_blank">
                {' '}
                Privacy Policy{' '}
              </Link>
            </CardText>
          )}
          {orgData?.policies?.return_policy?.content && (
            <CardText style={{ textDecoration: 'underline' }}>
              <Link to={`policies/return_policy`} target="_blank">
                {' '}
                Return Policy{' '}
              </Link>
            </CardText>
          )}
          {orgData?.policies?.shipping_policy?.content && (
            <CardText style={{ textDecoration: 'underline' }}>
              <Link to={`policies/shipping_policy`} target="_blank">
                {' '}
                Shipping Policy{' '}
              </Link>
            </CardText>
          )}
        </div>
      </Col>
    )
  }

  const renderSocialMediaLinks = () => {
    return (
      <Col className="mb-4 mb-md-0" md="4" xs="12">
        <div className="w-75 mx-auto">
          <h4 className="mt-2 mb-1">Social</h4>
          <CardText style={{ textDecoration: 'underline' }}>
            <a href={orgData?.social_media_links?.facebook} target="_blank" rel="noreferrer">
              {' '}
              Facebook{' '}
            </a>
          </CardText>
          <CardText style={{ textDecoration: 'underline' }}>
            <a href={orgData?.social_media_links?.twitter} target="_blank" rel="noreferrer">
              {' '}
              Twitter{' '}
            </a>
          </CardText>
          <CardText style={{ textDecoration: 'underline' }}>
            <a href={orgData?.social_media_links?.instagram} target="_blank" rel="noreferrer">
              {' '}
              Instagram{' '}
            </a>
          </CardText>
        </div>
      </Col>
    )
  }

  const renderPoweredby = () => {
    return (
      <div>
        <div className="mt-4">
          <h5 style={{ textDecoration: 'underline' }}>
            <a href="http://www.nustore.co">Powered by Nustore</a>
          </h5>
        </div>
        <div className="mt-1">
          <h5 style={{ textDecoration: 'underline' }}>
            <a href="http://www.nuventistech.com">A Nuventis Technology Product</a>{' '}
          </h5>
        </div>
        <div className="mt-3 mb-2">
          COPYRIGHT © 2023 Nuventis Technology LLP, All rights reserved
        </div>
      </div>
    )
  }

  return (
    <div className="footer-layout">
      <Row className="text-center">
        {renderShopAddress()}
        {renderSocialMediaLinks()}
        {renderPolicies()}
        {renderPoweredby()}
      </Row>
    </div>
  )
}

export default Footer
