export default [
  {
    id: 'shop',
    title: 'Shop',
    navLink: 'shop'
  },
  {
    id: 'about',
    title: 'About',
    navLink: 'aboutUs'
  },
  {
    id: 'contactus',
    title: 'Contact',
    navLink: 'contactUs'
  }
]
